import VisionPro from './VisionPro'
import css from './Home.module.css'
import { useTransitionView } from '../../hooks/useTransitionView'

const StartButton = ({ label, onClick })=> (
  <button className={css.button} onClick={onClick}>
    <code>{label}</code>
  </button>
)

const Home = ()=> {

  const transition = useTransitionView()
  
  const handleClick = ()=> {
    transition({ 
      to: '/start', 
      skipAnimation: true
    })
  }

  return (
    <div className={css.main}>
      <div className={css.image}>
        <VisionPro className={css.logo} />
      </div>
      <StartButton label='Empezar' onClick={handleClick} />
    </div>
  )
}

export default Home