import { usePracticeGameInfo, useQuickGameInfo } from "../../context/GameState"
import { useTransitionView } from "../../hooks/useTransitionView"
import { getColors } from "../../utils/levels"
import { clearGame } from "../../utils/storage"
import css from './Start.module.css'

const StartTitle = ({ prefix, title, suffix })=> {
  const letters = title.split('')
  return (
    <h1 className={css.title}>
      {prefix}{' '}
      {letters.map((letter, i)=> (
        <span key={i} className={css.titleLetter}>{letter}</span>
      ))}
      {' '}{suffix}
    </h1>
  )
}

const StartSubtitle = ({ subtitle })=> (
  <p className={css.intro}>
    🧠{' '}<code>{subtitle}</code>
  </p>
)

const StartButton = ({ label, onClick })=> (
  <button className={css.startButton} onClick={onClick}>
    <code>{label}</code>
  </button>
)

const Start = ()=> {

  const transition = useTransitionView()

  const { resetPractice } = usePracticeGameInfo()
  const { resetQuickGame } = useQuickGameInfo()
 
  const handlePractice = ()=> {
    clearGame({ type: 'practice' })
    resetPractice()
    transition({
      to: "/practice/1",
      sound: "twinkle"
    })
  }

  const handleQuickGame = ()=> {
    clearGame({ type: 'quick' })
    resetQuickGame()
    transition({
      to: "/quick/1",
      sound: "twinkle"
    })
  }

  return (
    <div className={css.main}>
      <StartTitle prefix='' title='colorful' suffix='- el juego' />
      <StartSubtitle subtitle='Un juego diseñado para retar a tu mente' />
      <div className={css.guide}>
        {getColors().map(color=> (
          <div key={color.color} className={css.guideColor} data-bg={color.color}>
            <p className={css.colorName}>{color.label}</p>
          </div>
        ))}
      </div>
      <div className={css.buttons}>
        <StartButton label='Practicar' onClick={handlePractice} />
        <StartButton label='Desafío' onClick={handleQuickGame} />
      </div>
    </div>
  )
}

export default Start