import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePracticeGameInfo, useQuickGameInfo } from '../../context/GameState'
import useTimeout from '../../hooks/useTimeout'
import { useTransitionView } from '../../hooks/useTransitionView'
import { clearGame } from '../../utils/storage'
import css from './End.module.css'

const EndTitle = ({ title })=> (
  <h1 className={css.title}>
    <code>{title}</code>
  </h1>
)

const EndStats = ({ resultPrefix, correct, total, resultSuffix, timeInfo, totalTime  })=> (
  <p className={css.intro}>
    <code>
      {resultPrefix} <span data-color="green">{correct}</span>/{total} {resultSuffix}
      <br />
      {timeInfo} <span data-color="pink">🕒 {totalTime}s</span>
    </code>
  </p>
)

const EndResults = ({ data, levelPrefix })=> (
  <div className={css.results}>
    <table className={css.table}>
      <tbody>
        {data.map((item, index)=> (
          <tr className={css.row} key={index}>
            <td className={css.levelCell}>
              <div 
                className={css.ball} 
                data-status={item.status}
              />
              <code>{levelPrefix} {item.level}</code>
            </td>
            <td className={css.scoreCell}>
              <span className={css.emoji}>🕒</span> <code>{item.score}s</code>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

const EndBackButton = ({ text, onClick })=> (
  <button className={css.home} onClick={onClick}>
    <svg className={css.house} viewBox="0 0 26.39 26.39">
      <path d="M3.588,24.297c0,0-0.024,0.59,0.553,0.59c0.718,0,6.652-0.008,6.652-0.008l0.01-5.451c0,0-0.094-0.898,0.777-0.898h2.761    c1.031,0,0.968,0.898,0.968,0.898l-0.012,5.434c0,0,5.628,0,6.512,0c0.732,0,0.699-0.734,0.699-0.734V14.076L13.33,5.913 l-9.742,8.164C3.588,14.077,3.588,24.297,3.588,24.297z" />
      <path d="M0,13.317c0,0,0.826,1.524,2.631,0l10.781-9.121l10.107,9.064c2.088,1.506,2.871,0,2.871,0L13.412,1.504L0,13.317z" />
      <polygon points="23.273,4.175 20.674,4.175 20.685,7.328 23.273,9.525"/>
    </svg>
    {text}
  </button>
)

const End = ()=> {

  const transition = useTransitionView()

  const { type } = useParams()

  const { practice } = usePracticeGameInfo()
  const { quickGame } = useQuickGameInfo()

  const [isCopied, setCopied] = useState(false)

  useTimeout(()=> {
    setCopied(false)
  }, isCopied ? 2000 : 0)

  useEffect(()=> {
    document.title = 'Resultados | Colorful'
    return ()=> document.title = 'Colorful'
  }, [])

  useEffect(()=> {
    clearGame({ type })
  }, [type])

  const data = (type === 'practice' ? practice : quickGame)
    .sort((a, b)=> a.level - b.level)

  const total = data.length
  const correct = data.filter(item=> item.status === 'correct').length
  const totalTime = data.reduce((acc, item)=> acc + +item.score, 0).toFixed(1)

  const handleNavigateHome = ()=> {
    transition({
      to: "/",
      sound: "click"
    })
  }

  return (
    <div className={css.main}>
      <EndTitle title='Fin del juego' />
      <EndStats 
        resultPrefix='Has respondido correctamente'
        correct={correct} 
        total={total} 
        resultSuffix='niveles'
        timeInfo='Tu tiempo total es de'
        totalTime={totalTime}
      />
      <EndResults
        data={data}
        levelPrefix='Nivel'
      />
      <EndBackButton
        onClick={handleNavigateHome}
        text='Volver a inicio'
      />
    </div>
  )
}

export default End
